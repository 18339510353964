import {
  FaTwitter,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

const data = [
   
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/MyLuckyN",
  },
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/profile.php?id=100088424017144",
  },
   
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/MyLuckyNumbers",
  },
   
];

export default data;
