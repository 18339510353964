 import Button from "../../../../common/button";
 import ReactDOM from 'react-dom';
 import React from 'react';
import BannerV1Wrapper from "./Banner.style";
import MintModalStyleWrapper from "./MintNow.style";
import characterThumb from "../../../../assets/images/nft/Character1.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/StakingfanGiro.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { useState, useEffect } from "react";
import {
  RecompensasRetirada,
  BalanceContratoCall,
  TotalNFTsMinteadoCall,
  NFTsoldsCall,
  
  TotalNFTsQuemadosCall,
  activame,
  getTotalDepositadoCall,
  getTotalReclamadoCall,
  reclamarDividendo,
  consultarRecompensasDisponibles,
  MisNFT,
  isRegister
 
  
} from "../../../../contract1/config";

import {
  RecompensasRetirada_old
}from "../../../../contract_old/config";
 
const Banner = () => {
  
  
  const [recompensasDisponibles, setRecompensasDisponibles] = useState(null);
  const [TotalNFTsMinteado, setTotalNFTsMinteado] = useState(0);
  const [TotalNFTsQuemados, setTotalNFTsQuemados] = useState(0);
  const [BalanceContrato, setBalanceContrato] = useState(0.00);
  const [getTotalDepositado, setgetTotalDepositado] = useState(0.00);
  const [getTotalReclamado, setgetTotalReclamado] = useState(0.00);
  const [MisNFTs, setMisNFTs] = useState(null);
  const [NFTSolds, setNFTSolds] = useState(0);
  const [old_Value, setold_Value] = useState(0)
  const Equilibrio = "20810000000000000000";
   
  const [ RecompensasRetiradas, setRecompensasRetiradas] = useState(null);
  const [ RecompensasRetiradas_old, setRecompensasRetiradas_old] = useState(null);
  const [ isRegistered, setisRegistered] = useState(null);

  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  const { data: TotalNFTsMinteadoData } = useContractRead({ ...TotalNFTsMinteadoCall})
  const { data: NFTSoldsData } = useContractRead({ ...NFTsoldsCall})
  const { data: TotalNFTsQuemadosData } = useContractRead({ ...TotalNFTsQuemadosCall})
  const { data: BalanceContratoData } = useContractRead({ ...BalanceContratoCall})
  const { data: getTotalDepositadoData } = useContractRead({ ...getTotalDepositadoCall})
  const { data: getTotalReclamadoData } = useContractRead({ ...getTotalReclamadoCall})

  useEffect(() => {
    if (isConnected) {
    

      consultarRecompensasDisponibles()
      .then((resultado) => {
        setRecompensasDisponibles(resultado);
      })
      .catch((error) => {
        console.error(error);
      });

      MisNFT()
      .then((resultado) => {
        setMisNFTs(resultado);
      })
      .catch((error) => {
        console.error(error);
      });

      RecompensasRetirada()
      .then((resultado) => {
        setRecompensasRetiradas(resultado);
      })
      .catch((error) => {
        console.error(error);
      });

      RecompensasRetirada_old()
      .then((resultado) => {
        setRecompensasRetiradas_old(resultado);
      })
      .catch((error) => {
        console.error(error);
      });

      isRegister()
      .then((resultado) => {
        setisRegistered(resultado);
      })
      .catch((error) => {
        console.error(error);
      });

      if (TotalNFTsMinteadoData) {
        setTotalNFTsMinteado(TotalNFTsMinteadoData.toString());
      }
      if (NFTSoldsData) {
        setNFTSolds(NFTSoldsData.toString());
      }
       
      if (TotalNFTsQuemadosData) {
        setTotalNFTsQuemados(TotalNFTsQuemadosData.toString());
      }

      if (BalanceContratoData) {
        setBalanceContrato(BalanceContratoData.toString() / 1000000000000000000);
      }

      if (getTotalDepositadoData) {
        setgetTotalDepositado(getTotalDepositadoData.toString() / 1000000000000000000);
      }

      if (getTotalReclamadoData) {
        setgetTotalReclamado(getTotalReclamadoData.toString() / 1000000000000000000);
      }

    }

    if (isConnected){
      setold_Value(Equilibrio)
    }
    
  });

   
  const mintNow = async () => {
    try {
      if (isConnected) {
        let txnHash = await reclamarDividendo();
        console.log("Txn Hash:", txnHash);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = "Error: " + error.message;
      ReactDOM.render(
        <div className="error-message">{errorMessage}</div>,
        document.getElementById("error-message")
      );
    }
  };
  
  const activemeNow = async () => {
    try {
      if (isConnected) {
        let txnHash = await activame();
        console.log("Txn Hash:", txnHash);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = "Error: " + error.message;
      ReactDOM.render(
        <div className="error-message">{errorMessage}</div>,
        document.getElementById("error-message")
      );
    }
  };
    
    

  

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <div className="coin-info">
              <h2>Detalles del Pool</h2>
              <span>Supply Total de NFTs :<span className="highlighted"> {TotalNFTsMinteado} SF</span>
              </span>
              <span>Total NFTs Vendidos:<span className="highlighted"> {NFTSolds } SF</span>
              </span>
              <span>Total NFTs Disponibles:<span className="highlighted"> {TotalNFTsMinteado - NFTSolds} SF</span>
              </span>
              <span>Total NFTs Quemados:🔥<span className="highlighted"> {TotalNFTsQuemados} SF</span>
              </span>
              <span>Beneficios Depositado:<span className="highlighted"> {( getTotalDepositado - (old_Value.toString()/1000000000000000000)).toFixed(2)} Matics</span>
              </span>
              <span>Beneficios Retirado:<span className="highlighted"> {getTotalReclamado.toFixed(2)} Matics</span>
              </span>
              <span>Balance del Contrato:<span className="highlighted"> {BalanceContrato.toFixed(2)} Matics</span>
              </span>
             <div>

             </div>
              <h2>Mi Cuenta</h2>
               
              <div>
              {MisNFTs !== null && (
              <span>Mis Acciones NFTs:<span className="highlighted"> {MisNFTs.toString()} SF</span>
              </span>
               )}
               </div>
               <div>
              {RecompensasRetiradas !== null && (
              <span>Recompensas Reclamadas:<span className="highlighted"> {((RecompensasRetiradas).toString()/1000000000000000000 + (RecompensasRetiradas_old).toString()/1000000000000000000).toFixed(2)} Matics</span>
              </span>
               )}
               </div>
              <div>
              {recompensasDisponibles !== null && (
              <span>Recompensas Disponibles:<span className="highlighted"> {(recompensasDisponibles.toString()/1000000000000000000).toFixed(2)} Matics</span>
              </span>
               )}
               </div>
              </div>
              <>
      <MintModalStyleWrapper >
      <div className="coin-info">
       

      <div>
  {isRegistered === false && MisNFTs < 1 ? (
    <span>Estatus de mi Cuenta:<span className="highlighted"> No eres accionista❌</span></span>
  ) : isRegistered ? (
    <span>Estatus de mi Cuenta:<span className="highlighted"> Lista para reclamar✔️</span></span>
  ) : (
     ""
  )}
  
  {isRegistered === false && MisNFTs > 0? (
    <span>Estatus de mi Cuenta:<span className="highlighted"> Debes activar tu cuenta🔑</span></span>
  ) : null}
</div>


      </div>
      </MintModalStyleWrapper>
    </>
              <div className="banner_buttons">
                <Button lg variant="mint" onClick={() => mintNow()}>
                  {" "}
                  Reclamar {recompensasDisponibles !== null && (
              (recompensasDisponibles.toString()/1000000000000000000).toFixed(2)
               )}{" Matic"}
                </Button>
                
              
                <Button lg variant="outline" onClick={() => activemeNow()} > 
                  Activame Ahora🔑
                </Button>
                 
              </div>
              <div className="coin-info">
                <span>
                  EL ÚNICO REQUISITO PARA RECLAMAR ES:{" "}
                  <span className="highlighted">ACTIVAR TU BILLETERA. HAZ CLICK EN "ACTIVAME AHORA", FIRMA LA TRANSACCION, ESPERA QUE SE CONFIRME Y LISTO!</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div>
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
