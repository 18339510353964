const data = [
  {
    title: "¿Qué es Staking Fan?",
    text: "Staking Fan es una colección NFT que fusiona el arte digital con el staking! Cada uno de nuestros 10,000 NFTs representa una acción de nuestro exclusivo portafolio de inversión en las criptomonedas BTT y TRX.",
    
  },
  {
    title: "¿Cuántos NFTs estarán disponibles en total?",
    text: "La colección estará compuesta por 10,000 NFTs, pero el contrato dispone de una función de quema lo que nos permitirá eliminar algunas acciones NFT en su momento.",
  },
  {
    title: "¿Cuánto cuesta un NFT?",
    text: "El precio de cada NFT estará determinado en un rango que va desde un mínimo de 3 USDC hasta un Máximo de 38 USDC, estos precios pueden no ser exactos ya que cada accionista puede vender al precio que considere e iniciar un trading con sus acciones. Están disponibles en Opensea.io",
    
  },
  {
    title: "¿Qué imagen está asociada con cada NFT?",
    text: "Cada NFT tiene una imagen asociada a su ID y cada una con atributos diferente.",
  },
  
  {
    title: "¿Por qué debería invertir en Staking Fan?",
    text: "Porque a pesar de ser una inversión en un activo sumamente volátil, ofrecemos un tipo de inversión rentable y estable a mediano y largo plazo, ya que nuestro fondo estan en staking en los nodos de BTT y TRX.",
  },
  {
    title: "¿Hay algún beneficio adicional al poseer un NFT de Staking Fan?",
    text: "Además de recibir dividendos por tener tus NFTs en la billetera, tendrá acceso a usar la energía que podamos obtener por medio del staking de los trx en fondo, además tus NFTs aumentaran su valor siempre ya que inyectamos al pool de un 20% a un 40% de lo que se genera en recompensa.",
  },
  {
    title: "¿Cómo puedo comprar un NFT Staking Fan?",
    text: "Puedes comprar un NFT de Staking Fan en el Marketplace de Opensea en la red de Poligon (Matic) utilizando el stable coin USDC.e, en caso de no tener puedes intercambiar el token que tenga en Quickswap.",
  },
  {
    title: "¿Qué pasa si quiero vender mi NFT de Staking Fan?",
    text: "Puedes vender tus NFTs de Staking Fan en cualquier plataforma de NFT que admita la red de Poligon (Matic), recomendamos a Opensea.",
  },
  {
    title: "¿Hay algún riesgo asociado con la inversión en Staking Fan?",
    text: "Como en cualquier inversión, siempre hay un riesgo asociado, pero como este proyecto está basado en un pool de staking es poco probable que nuestros NFTs pierdan su valor, a menos que los activos en los cuales tenemos la inversión (BTT y TRX) pierdan su usabilidad y de paso su valor. De todos modos asegúrate de hacer tu propia investigación y entender completamente los riesgos antes de invertir.",
  },
];

export default data;
