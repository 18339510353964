import { useModal } from "../../../../utils/ModalContext";
import { useEffect, useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { MdNotes } from "react-icons/md";
import Button from "../../../../common/button";
import NavWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import logo from "../../../../assets/images/logo.png";
import { ConnectButton } from '@rainbow-me/rainbowkit';
const Header = () => {
  const { walletModalHandle } = useModal();
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };
  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  return (
    <>
      <NavWrapper className="bithu_header" id="navbar">
        <div className="container">
          {/* Main Menu Start */}
          <div className="bithu_menu_sect">
            <div className="bithu_menu_left_sect">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="bithu nft logo" />
                </a>
              </div>
            </div>
            <div className="bithu_menu_right_sect bithu_v1_menu_right_sect">
              <div className="bithu_menu_list">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="https://stakingfan.space/#about">Acerca</a>
                  </li>
                  <li>
                    <a href="https://stakingfan.space/#roadmap">Roadmap</a>
                  </li>
                  <li>
                    <a href="/MiStaking">Mis Ingresos</a>
                  </li>
                  <li>
                    <a href="https://stakingfan.space/#faq">FAQ</a>
                  </li>
                  <li className="submenu">
                    <a href="# ">Mas +</a>
                    <div className="sub_menu_sect">
                      <ul className="sub_menu_list">              
                        <li>
                        <a href="https://polygonscan.com/address/0x0e5Bf44f99bA09571135B1d62f3C5A886569aA2B"
                           target="_blank"
                           rel="noreferrer"
                           >NFT ADDRESS</a>
                           </li>
                        <li>
                          <a href="https://drive.google.com/file/d/1-wkpN-u2FGkXHAELGE5q2DQTXAZqeOSZ/view"
                           target="_blank"
                           rel="noreferrer"
                          >Libro SF</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="bithu_menu_btns">
                <button className="menu_btn" onClick={() => handleMobileMenu()}>
                  <MdNotes />
                </button>
                <a  href="https://t.me/StakingfanOficial"
                target="_blank"
                rel="noreferrer"
              >
                <Button sm variant="outline" className="join_btn">
                  <FaTelegramPlane /> Únete
                </Button>
                </a>
                                 
                <ConnectButton 
                 label="Conectar"
                 chainStatus="none"//icon,name,none
                 showBalance={false}//true,false
                 accountStatus="address"//avatar,address,
                                  //className="connect_btn"
                 
                />
                  
                {/* <Button
                  sm
                  variant="hovered"
                  className="connect_btn"
                  onClick={() => walletModalHandle()}
                >
                  <FaWallet /> Connect
                </Button> */}
              </div>
            </div>
          </div>
          {/* <!-- Main Menu END --> */}
        </div>
      </NavWrapper>
      {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
    </>
  );
};

export default Header;
