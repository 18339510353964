import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
 
import AboutPage from "../pages/about";
import RoadmapPage from "../pages/roadmap";
 
import ContactPage from "../pages/contact";
import MintPageOne from "../pages/mint-1";
import MiStaking from "../pages/MiStaking";
import Administracion from "../pages/Administracion";
import FAQPage from "../pages/faq";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV1 />} exact />
       
      <Route path="/about" element={<AboutPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/roadmap" element={<RoadmapPage />} />
       
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/mint-1" element={<MintPageOne />} />
      <Route path="/MiStaking" element={<MiStaking />} />
      <Route path="/Administracion" element={<Administracion />} />
    </Routes>
  );
}

export default App;
