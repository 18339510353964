import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.png"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.png"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.png"

 const data = {
    aboutDescription1: "Staking Fan es una oportunidad única en el mundo de las inversiones y las criptomonedas. Somos un proyecto innovador basado en la blockchain de Polygon, donde cada NFT representa una acción en nuestro exclusivo portafolio de inversión en los emocionantes tokens BTT y TRX. ",

    aboutDescription2: " Nuestro equipo ha creado un portafolio estratégico diseñado para maximizar el potencial de ganancias a largo plazo. Al poseer uno de nuestros NFTs, te conviertes en un accionista y obtienes una participación en las ganancias generadas por nuestro portafolio.",
    
    aboutDescription3: "Como accionista de Staking Fan, no solo te beneficias de las posibles ganancias por el crecimiento del fondo de inversión, sino que también recibes dividendos mensuales en la criptomoneda Matic. Cuantas más acciones poseas, mayores serán tus ganancias pasivas.",
   
    aboutDescription4: "¿Estás listo para unirte a la revolución financiera con Staking Fan? ¡No esperes más! Únete a nosotros hoy y sé parte de una emocionante travesía hacia un futuro financiero más brillante.",
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Ingresos pasivos",
            text: "Los propietarios recibiran ingresos sin hacer el staking de sus NFTs."
        },
        {
            icon: aboutCardIcon2,
            title: "Quema de NFTs",
            text: 'Función de quema para reducir el Supply. '
        },
        {
            icon: aboutCardIcon3,
            title: "Interés Compuesto",
            text: 'Reinversión del 40% de los beneficios de forma inteligente: incremento de fondo y reducción de acciones.'
        }
    ]
}

export default data;