 import Button from "../../../../common/button";
 import ReactDOM from 'react-dom';
 import React from 'react';
import BannerV1Wrapper from "./Banner.style";
import characterThumb from "../../../../assets/images/nft/Character1.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/StakingfanGiro.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { useState, useEffect } from "react";
import {
  depositarDividendo,
} from "../../../../contract1/config";
import {
  publicBurn
} from "../../../../contract/config";
 
const Banner = () => {
  
  const [depositValue, setDepositValue] = useState('');
  const [burnValue, setBurnValue] = useState('');

  const { address, isConnecting, isConnected, isDisconnected } = useAccount();


  const handleDepositChange = (event) => {
    setDepositValue(event.target.value);
  };

  const handleBurnChange = (event) => {
    setBurnValue(event.target.value);
  };
   
  const depositNow = async () => {
    
      if (isConnected) {
        let txnHash = await depositarDividendo(depositValue);
        console.log("Txn Hash:", txnHash);
      }
  };
  
  const burnMeNow = async () => {
    
      if (isConnected) {
        let txnHash = await publicBurn(burnValue);
        console.log("Txn Hash:", txnHash);
       
    }
  };
    
    

  

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <div className="coin-info">
              <h2>Depósito:</h2>

              <div className="banner_buttons">

              <div >
     
      <label htmlFor="numberInput" style={{ fontSize: "16px", marginBottom: "8px" , color: "white"}}>Ingresa el Monto:</label>
      <input
        type="number"
        id="numberInput"
        value={depositValue}
        onChange={handleDepositChange}
        style={{ width: "100%", padding: "8px", fontSize: "14px" }}
      />
       

       
    </div>
                <Button lg variant="mint" onClick={() => depositNow()}>
                  {" "}
                  Depositar Dividendos💵
                </Button>
                 
              </div>
                           
              
              <h2>Quema:</h2>
              <div className="banner_buttons">       
              <div>
      
      <label htmlFor="numberInput" style={{ fontSize: "16px", marginBottom: "8px" , color: "white"}}>Ingresa # de NFT:</label>
      <input
        type="number"
        id="numberInput"
        value={burnValue}
        onChange={handleBurnChange}
        style={{ width: "100%", padding: "8px", fontSize: "14px" }}
      />
      
    </div>
                <Button lg variant="outline" onClick={() => burnMeNow()} > 
                🔥Quemar NFT🔥
                </Button>
                 
              </div>
              
              </div>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div>
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
