import {ethers} from 'ethers';
import ContractABI from "./contractABI.json";

const contractAddress = "0x012ea1AB598923F5777f97835D30D80e1a9574c1";
//contract configuration
export const contractConfig = {
    address: contractAddress,
    abi: ContractABI,
}

 

//Total Recompensas Retiradas
export const RecompensasRetiradasCall = {
    ...contractConfig,
    functionName: 'RecompensasRetiradas',
    watch: true,
}
 

 

  export async function RecompensasRetirada_old() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SFDividendo = new ethers.Contract(contractAddress, ContractABI, signer);
  
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    let txnHash;

    const transaction = await SFDividendo.RecompensasRetiradas();
    
    txnHash = transaction;
    return txnHash;
  }

 