import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";
import { FaTelegramPlane, FaTwitter} from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import logo from "../../../../assets/images/logo.png";
import openseaIcon from "../../../../assets/images/icon/opensea.svg";

import MobileMenuStyleWrapper from "./MobileMenu.style";

import { ConnectButton } from '@rainbow-me/rainbowkit';

const MobileMenu = ({ mobileMenuhandle }) => {
  const { walletModalHandle } = useModal();
  const [isSubmenu, setSubmenu] = useState(false);

  const handleSubmenu = () => {
    setSubmenu(!isSubmenu);
  };
  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="https://stakingfan.space/#about">Acerca</a>
            </li>
            <li>
              <a href="https://stakingfan.space/#roadmap">Roadmap</a>
            </li>
            <li>
              <a href="/MiStaking">Mis Ingresos</a>
            </li>
            <li>
              <a href="https://stakingfan.space/#faq">FAQ</a>
            </li>
            <li className="submenu mobile_submenu" onClick={handleSubmenu}>
              <a href="# ">Mas+</a>
              <ul
                className={`sub_menu_list mobile_sub_menu_list ${isSubmenu === true && "submenu_hovered"
                  }`}
              >
                
                <li>
                  <a href="https://polygonscan.com/address/0x0e5Bf44f99bA09571135B1d62f3C5A886569aA2B"
                   target="_blank"
                   rel="noreferrer"
                  >NFT ADDRESS</a>
                </li>

                <li>
                  <a href="https://drive.google.com/file/d/1-wkpN-u2FGkXHAELGE5q2DQTXAZqeOSZ/view"
                   target="_blank"
                   rel="noreferrer"
                  >Libro SF</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a href="https://opensea.io/collection/stakingfan">
            <img src={openseaIcon} alt="bithu social icon" />
          </a>
          <a href="https://twitter.com/JensyAntonio ">
            <FaTwitter />
          </a>
          <a href="https://t.me/StakingfanOficial">
            
            <FaTelegramPlane />
          </a>
        </div>
        <ConnectButton
          label="Connect"
          chainStatus="none"//icon,name,none
          showBalance={false}//true,false
          accountStatus="address"//avatar,address,
        //className="connect_btn"
        />
        {/* <Button
          sm
          variant="hovered"
          className="connect_btn"
          onClick={() => walletModalHandle()}
        >
          <FaWallet /> Connect
        </Button> */}
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
