// src/components/ContactSection.js
import React from 'react';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import styled from 'styled-components';

const ContactSectionWrapper = styled.section`
  padding-bottom: 60px;
  padding-top: 65px;
  text-align: center;
  color: green;
  max-width: 770px;
    width: 100%;
    margin: auto;
    position: relative;

  h2 {
    color: white;
  }

  p {
    text-align: justify;
    justify-content: center;
    display: flex;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(10px);
      margin-top: 10px;
      padding: 0px 48px 24px 29px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: #33FFFC;

      strong {
        display: relative;
        align-items: center;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 18px;
      line-height: 28px;
    }
  }

  @media only screen and (max-width: 500px) {
    h2 {
      font-size: 20px;
      line-height: 25px;
    }

    p {
      font-size: 14px;
      line-height: 25px;
    }
  }

  @media only screen and (max-width: 480px) {
    h2 {
      font-size: 18px;
    }
  }
`;

function Contact() {
  return (
    <ContactSectionWrapper>
      <h2>Bienvenido a nuestra sección de contacto</h2>
      <p>
        Estamos encantados de ayudarte. Aquí tienes nuestras informaciones de contacto en caso de que tengas dudas,
         tus inquietudes son muy importantes para nosotros:
      </p>
      <ul>
        
        <li>
          <strong>
            <FaEnvelope  />{" "} Emails:
          </strong> {" "}
          stakingfanoficial@gmail.com / jarc83@hotmail.com
        </li>
        <li>
        <strong>
            <FaWhatsapp   />{" "} WhatsApp:
            </strong> {" "}
          +1(829)278-8912
        </li>
      </ul>
    </ContactSectionWrapper>
  );
}

export default Contact;
