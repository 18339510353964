import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Fase 01 (Fondos) ",
        subtitle: "En Progreso",
        text: "Obtención de fondos por las ventas de los NFTs y así crear pools de staking."
    }, 
    {
        icon: checkIcon,
        title: "Fase 02 (Staking)",
        subtitle: "En Aumento",
        text: "Cada nft que se vende se convierte a BTTC y se le inyecta al pool de Staking."
    }, 
    {
        icon: checkIcon,
        title: "Fase 03 (Staking de NFTs)",
        subtitle: "En Vivo",
        text: "Creación de un contrato que permita a nuestros holders recibir ingresos sin hacer staking."
    }, 
    {
        icon: spinnerIcon,
        title: "Fase 04 (Interés Comp.)",
        subtitle: "En Vivo",
        text: "Reinversión del 40% de los beneficios de forma inteligente: incremento de fondo y reducción de acciones."
        
    }, 
         
]

export default data;