import {ethers} from 'ethers';
import ContractABI from "./contractABI.json";

const contractAddress = "0x5C2ad4C36fffF4e7d3cbB4dFF9878Fba9ea8a0Ae";
 
//contract configuration
export const contractConfig = {
    address: contractAddress,
    abi: ContractABI,
}

 

//Total Recompensas Retiradas
export const RecompensasRetiradasCall = {
    ...contractConfig,
    functionName: 'RecompensasRetiradas',
    watch: true,
}

//Total Balance en Contrato
export const BalanceContratoCall = {
    ...contractConfig,
    functionName: 'BalanceContrato',
    watch: true,
}

//Total NFTs Minteado
export const TotalNFTsMinteadoCall = {
    ...contractConfig,
    functionName: 'TotalNFTsMinteado',
    watch: true,
}

//Total NFTs Solds
export const NFTsoldsCall = {
  ...contractConfig,
  functionName: 'NFTSolds',
  watch: true,
}


//Total NFTs Quemados
export const TotalNFTsQuemadosCall = {
    ...contractConfig,
    functionName: 'TotalNFTsQuemados',
    watch: true,
}

//Total Depositado
export const getTotalDepositadoCall = {
    ...contractConfig,
    functionName: 'getTotalDepositado',
    watch: true,
}

//Total Reclamado
export const getTotalReclamadoCall = {
    ...contractConfig,
    functionName: 'getTotalReclamado',
    watch: true,
}


export async function consultarRecompensasDisponibles() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SFDividendo = new ethers.Contract(contractAddress, ContractABI, signer);
  
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    let txnHash;

    const transaction = await SFDividendo.RecompensasDisponible();
    
    txnHash = transaction;
    return txnHash;
  }

  export async function MisNFT() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SFDividendo = new ethers.Contract(contractAddress, ContractABI, signer);
  
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    let txnHash;

    const transaction = await SFDividendo.MisNFTs();
    
    txnHash = transaction;
    return txnHash;
  }
   

  export async function RecompensasRetirada() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SFDividendo = new ethers.Contract(contractAddress, ContractABI, signer);
  
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    let txnHash;

    const transaction = await SFDividendo.RecompensasRetiradas();
    
    txnHash = transaction;
    return txnHash;
  }

  export async function isRegister() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SFDividendo = new ethers.Contract(contractAddress, ContractABI, signer);
  
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    let txnHash;

    const transaction = await SFDividendo.isRegistered(account);
    
    txnHash = transaction;
    return txnHash;
  }









//reclamar Dividendos
export const reclamarDividendo = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const SFDividendo = new ethers.Contract(contractAddress, ContractABI, signer);
  
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const account = accounts[0];
    let txnHash;
    
    const gasLimit = 285000;
    const gasPrice = await provider.getGasPrice();
    const transaction = await SFDividendo.reclamarDividendos({
      gasLimit: gasLimit,
      gasPrice: gasPrice
    });
    
    txnHash = transaction.hash;
    return txnHash;
  }
  
//reclamar Dividendos
export const activame = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const SFDividendo = new ethers.Contract(contractAddress, ContractABI, signer);

  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  const account = accounts[0];
  let txnHash;
  
  const gasLimit = 285000;
  const gasPrice = await provider.getGasPrice();
  const transaction = await SFDividendo.Activame({
    gasLimit: gasLimit,
    gasPrice: gasPrice
  });
  
  txnHash = transaction.hash;
  return txnHash;
}

//public Depositar
export const depositarDividendo = async (Amout_Dividendo) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);

  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  let account = accounts[0];
  let txnHash;
  
  txnHash = await nftContract.depositarDividendos({
      gasLimit: "285000",
      value: ethers.utils.parseEther((Amout_Dividendo).toString())
  })

  return txnHash;
}