const data = [
    {
        num: "1",
        mintColor: "green",
        text: "Ve a Opensea "
    },
    {
        num: "2",
        mintColor: "blue",
        text: "Conecta tu billetera"
    },
    {
        num: "3",
        mintColor: "yellow",
        text: "Elige tus NFTs"
    },
    {
        num: "4",
        mintColor: "red",
        text: "Confirma la Transacción"
    },
    ]

export default data;