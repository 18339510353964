import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import openseaIcon from "../../../../assets/images/icon/opensea.svg";
import characterThumb from "../../../../assets/images/nft/Character1.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";

import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { useState, useEffect } from "react";
import {
  maxSupplyCall,
  totalMintedCall,
  maxMintPerWalletCall,
  publicMintCostCall,
} from "../../../../contract/config";

import{
  TotalNFTsMinteadoCall,
  NFTsoldsCall,
  TotalNFTsQuemadosCall,
} from "../../../../contract1/config";

const Banner = () => {
  const { mintModalHandle } = useModal();

  const [totalSupply, setTotalSupply] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [TotalNFTsMinteado, setTotalNFTsMinteado] = useState(0);
  const [TotalNFTsQuemados, setTotalNFTsQuemados] = useState(0);
  const [NFTSolds, setNFTSolds] = useState(0);
  const [remainingItem, setRemainingItem] = useState(0);
  const [maxMintPerWallet, setMaxMintPerWallet] = useState(0);
  const [publicMintCost, setPublicMintCost] = useState(0);
  const { address, isConnecting, isConnected, isDisconnected } = useAccount();

  const { data: maxSupplyData } = useContractRead({ ...maxSupplyCall })
  const { data: totalMintedData } = useContractRead({ ...totalMintedCall })
  const { data: TotalNFTsQuemadosData } = useContractRead({ ...TotalNFTsQuemadosCall})
  const { data: maxMintPerWalletData } = useContractRead({ ...maxMintPerWalletCall })
  const { data: publicMintCostData } = useContractRead({ ...publicMintCostCall })
  const { data: TotalNFTsMinteadoData } = useContractRead({ ...TotalNFTsMinteadoCall})
  const { data: NFTSoldsData } = useContractRead({ ...NFTsoldsCall})

  useEffect(() => {
    if (isConnected) {
      if (maxSupplyData) {
        setTotalSupply(maxSupplyData.toString());
      }

      if (totalMintedData) {
        setTotalMinted(totalMintedData.toString());
      }
      if (maxSupplyData && totalMintedData) {
        setRemainingItem(totalSupply - totalMinted);
      }
      if (maxMintPerWalletData) {
        setMaxMintPerWallet(maxMintPerWalletData.toString());
      }
      if (TotalNFTsQuemadosData) {
        setTotalNFTsQuemados(TotalNFTsQuemadosData.toString());
      }
      if (publicMintCostData) {
        setPublicMintCost(publicMintCostData.toString() / 1000000000000000000);
      }

      if (TotalNFTsMinteadoData) {
        setTotalNFTsMinteado(TotalNFTsMinteadoData.toString());
      }
      if (NFTSoldsData) {
        setNFTSolds(NFTSoldsData.toString());
      }

    }
  })

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">  
            <div className="bithu_v1_baner_left">
              <h2>Acciones NFTs de Staking Fan</h2>
              <h4>   Explora un nuevo nivel de participación en el mundo del staking y las inversiones con las ACCIONES NFTS DE STAKING FAN. Nuestra plataforma revolucionaria combina la potencia de los NFTs con la emisión de acciones, brindándote la oportunidad de poseer activos digitales únicos respaldados por proyectos de staking de vanguardia.</h4>
              <h4>    </h4>
              <h4>   Únete a la evolución financiera mientras te sumerges en un ecosistema donde las decisiones de gobernanza son guiadas por la comunidad. Además, disfruta de dividendos mensuales mientras participas en el reparto de ganancias. Cada acción está valorada en 300 Matics, permitiéndote ingresar a este emocionante mundo de inversión con facilidad. Descubre las ACCIONES NFTS DE STAKING FAN y define tu camino hacia el futuro financiero descentralizado.</h4>
               
              
              
              <div className="coin-info">
                <h3>Resumen ｡◕‿◕｡</h3> 

              <table>
  <thead className="tablehead">
    <tr>
      <th>Suministro</th>
      <th>En Circulación</th>
      <th>Quemados🔥</th>
      <th>Disponibles</th>
      
    </tr>
  </thead>
  <tbody className="tablebody">
    <tr>
      <td>{totalSupply}</td>
      <td>{NFTSolds}{" "}SF</td>
      <td>{TotalNFTsQuemados}</td>
      <td>{TotalNFTsMinteado - NFTSolds}</td>
    </tr>
       
  </tbody>
</table>
<div className="banner_buttons">
             
                <a  href="https://opensea.io/collection/stakingfan"
                target="_blank"
                rel="noreferrer"
              >
                <Button lg variant="outline" > 
                <img src={openseaIcon} alt="bithu social icon" /> Comprar en Opensea
                </Button>
                </a>
              </div>

               
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div>
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
